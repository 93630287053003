/******************************************************
 * @package Version Theme Framework for novatheme
 * @version 1.0
 * @author http://www.novatheme.com
 * @copyright Copyright (coffee) Augus 2013 novatheme.com <@emai:novatheme@gmail.com>.All rights reserved.
 * @license   GNU General Public License version 2
*******************************************************/

.vinovathemes {
	background: #000;
}